<template>
  <div class="mobile-list">
    <searchBox placeholder="请输入关键字" :onTextChanged="textChanged" :onCancel="cancel" :onFilterClicked="filterClicked" :onSearchClicked="searchClicked" :msg="enterkey"></searchBox>

    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <itemBox v-for="(item, index) in items" :code="item.model.code" :stateName="commonState(item.model.state)" :stateColor="getStatusColor(item.model.state)" :name="item.model.content" :user="item.model.users" :time="item.model.apply_time"
          :exts="[formatDate(item.model.s_time) + ' ~ ' + formatDate(item.model.e_time), item.model.project_name]" :key="index" @click.native="itemClick(item)" />
      </orderScroll>
    </div>

    <!-- <searchDrawer ref="searchDrawer" v-if="showingFilter" :onBgClicked="filterBgClicked" :onReset="resetFilter" :onConfirm="confirmFilter">
      <projectFilter ref="filter" :filterOption="filterOption" />
    </searchDrawer> -->

    <img class="mobile-list-add" src="@/static/images/icon-add.png" alt @click="add" style="display: block" />
  </div>
</template>

<script>
import { GetTravel } from "@/api";
import { commonState } from "@/utils/state.js";
import { formatDate } from "@/utils/format.js";
import { loadListAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      items: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      showingFilter: false,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
      },
      enterkey: [],
      keyword: "",
    };
  },
  methods: {
    commonState: commonState,
    formatDate: formatDate,
    getStatusColor: function (state) {
      switch (state) {
        case 1:
          return "grey-font-color";
        case 2:
          return "orange-font-color";
        case 3:
          return "red-font-color";
        case 4:
          return "link-font-color";
        default:
          return "green-font-color";
      }
    },
    cancel: function () {
      this.enterkey.length = 0;
    },
    add: function () {
      this.$router.push({
        name: "mTravelDetail",
        query: {
          id: "add",
        }
      });
    },
    itemClick: function (item) {
      this.$router.push({
        name: "mTravelDetail",
        query: {
          id: item.model.id,
        },
      });
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    filterClicked: function () {
      this.showingFilter = true;
      this.$nextTick(() => {
        this.$refs.searchDrawer.show();
      });
    },
    filterBgClicked: function () {
      this.showingFilter = false;
    },
    resetFilter: function () {
      this.filterOption = {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
        orderTypeKey: 1,
        orderSourceKey: null,
      };
      this.showingFilter = false;
      this.firstPage();
    },
    confirmFilter: function () {
      this.filterOption = this.$refs.filter.getOption();
      this.showingFilter = false;
      this.firstPage();
    },
    searchClicked: function (keywords) {
      this.keyword = keywords;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      loadListAction(this, GetTravel, {
        sTime: this.filterOption.startTime,
        eTime: this.filterOption.endTime,
        state: this.filterOption.orderStatusKey,
        keyword: this.keyword,
        page: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          orderBy: "time desc",
        }
      }, (data) => {
        this.total = data.totalCount;
        if (this.pageIndex == 1) {
          this.items = [];
        }
        if (data.list.length > 0) {
          this.items = this.items.concat(data.list);
          this.pageIndex += 1;
        }
        this.hasMore = this.total > this.items.length;
        this.$refs.scroll.forceUpdate(true);
      });
    }
  },
  mounted: function () {
    this.firstPage();
  },
};
</script>